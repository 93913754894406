import React, { useState } from 'react';
import { css } from 'linaria';
import { Action, Form, Toast } from 'mediasoil-shared-components/components/';
import cls from 'mediasoil-shared-components/utils/class_strings';
import Layout from '../components/layout';
import Main from '../components/main';
import SEO from '../components/seo';
import Quantity from '../components/quantity';
import { StoreContext, getStoreKey } from '../components/store';
import CheckIcon from 'Img/checkmark.svg';

const styles = {
  main: css`
    @include helpers.mq(header) {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto;
    }
  `,
  productDetails: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    grid-column: 2/3;
    grid-row: 1;
  `,
  title: css`
    border: solid currentColor;
    border-width: 3px 0;
    font-size: map-get(helpers.$font-sizes, h3);
    margin: 0 0 helpers.$gutter;

    @include helpers.mq(header, true) {
      width: 100%;
    }
  `,
  description: css`
    &:empty {
      display: none;
    }
  `,
  form: css`
    align-items: flex-start;
    border-bottom: 1px solid currentColor;
    display: flex;
    flex-direction: column;
    margin: 0 0 helpers.$gutter;
    padding: 0 0 helpers.$gutter;
    width: 100%;

    select {
      max-width: 100%;
    }

    [type='submit'] {
      margin-top: helpers.$gutter * 2;
      position: relative;
    }
  `,
  availabilityTitle: css`
    margin-top: 0;
  `,
  availabilityLocationList: css`
    margin: 0;
  `,
  productImage: css`
    display: block;
    grid-column: 1/2;
    grid-row: 1;

    @include helpers.mq(header) {
      margin: 0 helpers.$gutter * 2 0 0;
    }

    > img {
      width: 100%;

      @include helpers.mq(header) {
        max-width: 560px;
        width: 50vw;
      }
    }
  `,
  topGutter: css`
    margin-top: helpers.$gutter;
  `,
  toast: css`
    animation: updatedQuantity map-get(helpers.$transition, slow);
    background: inherit;
    border: 1px solid black;
    border-radius: 1em;
    font-size: 0.8em;
    padding: helpers.$gutter/4 helpers.$gutter/2;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(120%, -50%);
    z-index: map-get(helpers.$z, hidden);

    svg {
      height: 1.15em;
      width: 1.15em;
    }
  `,
};

export default ({ pageContext }) => {
  const { product, locations, inventory, canonical } = pageContext;
  const variation = product.variations[0];
  const variationInventory = inventory
    .filter(({ variation_id }) => variation_id === variation.id)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.location_id]: cur,
      }),
      {}
    );
  const variationLocations = variation.present_at_all_locations
    ? locations
    : locations.filter(({ id }) => variation?.present_at_location_ids?.includes(id));

  const [isQuantityUpdated, setIsQuantityUpdated] = useState(null);

  const currency = variation.price[0];
  const price = variation.price.slice(1);
  const storeKey = getStoreKey({ product: product.id, variation: variation.id });

  return (
    <Layout>
      <SEO
        description={product.description}
        title={product.name}
        image={{ url: `https://images-production-f.squarecdn.com/unsafe/560x560/${product.image}` }}
        link={[{ rel: 'canonical', href: canonical }]}
      />
      <Main className={styles.main} itemScope itemType="http://schema.org/Product">
        <StoreContext.Consumer>
          {([store, { setProduct }]) => (
            <>
              <div className={styles.productDetails}>
                <h1 className={styles.title} itemProp="name">
                  {product.name}
                </h1>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: product.description }}
                  itemProp="description"
                />
                <Form
                  className={styles.form}
                  action="/"
                  onSubmit={evt => {
                    setIsQuantityUpdated(true);
                    setProduct(evt);
                  }}
                >
                  <input type="hidden" name="product" value={product.id} />
                  <input type="hidden" name="variation" value={variation.id} />
                  <h2 className="h6">Price</h2>
                  <strong>
                    <span content="USD" itemProp="priceCurrency">
                      {currency}
                    </span>
                    <span itemProp="price" content={price}>
                      {price}
                    </span>
                  </strong>
                  {/* <h2 className="h6">Quantity*</h2>
                  <Quantity name="quantity" key={storeKey} defaultValue={store[storeKey]?.quantity || 0} />
                  <button type="submit">
                    Add to Curbside Delivery
                    {isQuantityUpdated && (
                      <Toast
                        className={styles.toast}
                        title={
                          <svg aria-label="Updated">
                            <use xlinkHref={`#${CheckIcon.id}`} />
                          </svg>
                        }
                        timer={750}
                        timerCallback={() => setIsQuantityUpdated(false)}
                      />
                    )}
                  </button> */}
                </Form>
                {variationLocations.length ? (
                  <>
                    <h2 className={cls('h5', styles.availabilityTitle)}>Available at the following locations*:</h2>
                    <ul className={styles.availabilityLocationList}>
                      {variationLocations
                        .filter(({ id }) => +variationInventory[id]?.quantity)
                        .map(({ id, name }) => {
                          {
                            /* const quantity = variationInventory[id]?.quantity; */
                          }

                          {
                            /* if (quantity != undefined) {
                            var stock = <small> ({quantity} Available)*</small>;
                          } */
                          }

                          return (
                            <li key={id}>
                              {name}
                              {/* {stock} */}
                            </li>
                          );
                        })}
                    </ul>
                    <p className={styles.topGutter}>
                      <small>
                        <b>*</b> Availability is subject to change and cannot be guaranteed.
                      </small>
                    </p>
                  </>
                ) : null}
              </div>
              <Action className={styles.productImage} href={product.image} target="productimage">
                <img
                  alt={product.name}
                  itemProp="image"
                  src={`https://images-production-f.squarecdn.com/unsafe/560x560/${product.image}`}
                  srcSet={`https://images-production-f.squarecdn.com/unsafe/1120x1120/${product.image} 2x`}
                />
              </Action>
            </>
          )}
        </StoreContext.Consumer>
      </Main>
    </Layout>
  );
};
